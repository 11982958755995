<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <Loading
          :active.sync="isLoading"
          color="#FF0000"
          background-color="transparent"
          :height="128"
          :width="128"
          :is-full-page="false"
        >
          <template v-slot:default> <Loader></Loader> </template>
        </Loading>
        <div class="article-content ready" style="opacity: 0" v-if="ready">
          <h1 class="title">PRIESKUM</h1>
          <div class="content-container">
            <p>
              <span class="red">
                Ak chcete začať prieskum,
                <a
                  href="https://ess.centerdata.nl/questionnaires/ess11_slovakia"
                  target="_blank"
                  >KLIKNITE TU.</a
                >
                <br /><br />
                <a
                  href="https://ess.centerdata.nl/questionnaires/ess11_slovakia"
                  target="_blank"
                >
                  https://ess.centerdata.nl/questionnaires/ess11_slovakia</a
                >
                <br /><br />
                SK5363JN
              </span>
              <br /><br />
              Ako prejav vďaky pošleme odmenu (5€) všetkým respondentom (osoba
              staršia ako 15 rokov s najbližšími narodeninami), ktorí vyplnia
              dotazník. <br /><br />
              Ak máte akékoľvek otázky, kontaktujte nás na čísle
              <span class="red">0944 631 186</span> alebo e-mailom na adrese
              <a href="mailto:zns@saske.sk">zns@saske.sk</a> <br /><br />Ako je
              uvedené v informačnom liste o ochrane osobných údajov, máte právo
              namietať proti spracovaniu vašich osobných údajov, opraviť a
              vymazať všetky informácie o vás a požiadať nás o informácie, ktoré
              o vás máme, pokiaľ vás dokážeme identifikovať. Máte tiež právo
              požiadať o pozastavenie spracovávania vašich údajov počas doby
              potrebnej na overenie ich správnosti a podať sťažnosť
              vnútroštátnemu dozornému orgánu. Ak sa chcete dozvedieť viac o
              prieskume, kliknite tu<br />
              <a
                href="https://www.europeansocialsurvey.org/about/country-information/slovakia/slovak"
                target="_blank"
              >
                https://www.europeansocialsurvey.org/about/country-information/slovakia/slovak</a
              >
              <br /><br />
              Informácie o ochrane osobných údajov pre účastníkov prieskumu si
              môžete pozrieť tu.
            </p>
          </div>
        </div>
        <div class="close-container" @click="onClose()">
          <div class="leftright" :class="{ ready: !isLoading }"></div>
          <div class="rightleft" :class="{ ready: !isLoading }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.red,
a {
  color: #ad1400 !important;
}
p {
  width: 80%;
  line-height: 130%;
  font-size: 18px;
}
</style>

<script>
import Loader from "./Loader-comp.vue";

export default {
  components: { Loader },
  data() {
    return {
      isLoading: false,
      data: null,
      ready: true,
    };
  },
  beforeMount() {
    // this.getData("shop");
  },
  methods: {
    // getData(slug) {
    //   axios
    //     .get(`/api/web/${slug}`, {
    //       headers: { "X-localization": this.$root.lang },
    //     })
    //     .then((resp) => {
    //       this.data = resp.data;
    //       this.ready = true;
    //       setTimeout(() => {
    //         this.isLoading = false;
    //       }, 500);
    //     });
    // },
    onClose() {
      this.$router.push("/");
    },
  },
};
</script>

<style></style>

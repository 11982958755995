<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <Loading
          :active.sync="isLoading"
          color="#FF0000"
          background-color="transparent"
          :height="128"
          :width="128"
          :is-full-page="false"
        >
          <template v-slot:default> <Loader></Loader> </template>
        </Loading>
        <div
          class="article-content"
          style="opacity: 0"
          v-if="ready"
          :class="{ ready: !isLoading }"
        >
          <h1 class="title">KONTAKT</h1>
          <div class="content-container">
            <p>
              <b>Národný tím</b><br />
              Michal Kentoš, 0944 631 186<br />
              <a href="mailto:zns@saske.sk">zns@saske.sk</a> <br />
              Centrum spoločenských a psychologických vied SAV<br />
              Karpatská 5, 040 01 Košice<br /><br />

              <b>Kontrolór</b><br />
              ESS ERIC, ESS ERIC HQ, c/o City,<br />
              University of London<br />
              <a href="mailto:ess@city.ac.uk">ess@city.ac.uk</a><br /><br />

              <b>Osoby dohliadajúce na ochranu údajov</b><br />
              Lasse Raa and Callan Ramewal<br />
              <a href="mailto:dpo.esseric@europeansocialsurvey.org"
                >dpo.esseric@europeansocialsurvey.org</a
              ><br /><br />

              <b>Národná autorita</b><br />
              Úrad na ochranu osobných údajov Slovenskej republiky,<br />
              Hraničná 12, 820 07 Bratislava 27, Slovenská republika<br />
              <a href="mailto:statny.dozor@pdp.gov.sk"
                >statny.dozor@pdp.gov.sk</a
              >
            </p>
          </div>
        </div>
        <div class="close-container" @click="onClose()">
          <div class="leftright" :class="{ ready: !isLoading }"></div>
          <div class="rightleft" :class="{ ready: !isLoading }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.red,
a {
  color: #ad1400 !important;
}
p {
  width: 80%;
  line-height: 130%;
  font-size: 18px;
}
</style>

<script>
import Loader from "./Loader-comp.vue";

export default {
  components: { Loader },
  data() {
    return {
      isLoading: false,
      data: null,
      ready: true,
    };
  },
  methods: {
    onClose() {
      this.$router.push("/");
    },
  },
};
</script>

<style></style>

import "./assets/app.scss";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAgile from "vue-agile";
import Loading from "vue-loading-overlay";

Vue.config.productionTip = false;

Vue.component("Loading-comp", Loading);

Vue.use(VueAgile);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <Loading
          :active.sync="isLoading"
          color="#FF0000"
          background-color="transparent"
          :height="128"
          :width="128"
          :is-full-page="false"
        >
          <template v-slot:default> <Loader></Loader> </template>
        </Loading>
        <div
          class="article-content"
          style="opacity: 0"
          v-if="ready"
          :class="{ ready: !isLoading }"
        >
          <h1 class="title">GDPR</h1>
          <div class="content-container">
            <p>
              <strong
                >VYHL&Aacute;SENIE O OCHRANE OSOBN&Yacute;CH &Uacute;DAJOV:
                Život na Slovensku</strong
              ><br /><strong
                >Život na Slovensku je prieskum soci&aacute;lnych postojov,
                n&aacute;zorov a spr&aacute;vania obyvateľov eur&oacute;pskych
                kraj&iacute;n.</strong
              >
            </p>
            <p>
              Obraciame sa na V&aacute;s so žiadosťou o &uacute;časť v tomto
              prieskume. Va&scaron;a dom&aacute;cnosť bola n&aacute;hodne
              vybran&aacute; v r&aacute;mci obce alebo ulice, na ktorej
              b&yacute;vate. Ochrana Va&scaron;ich osobn&yacute;ch &uacute;dajov
              je pre n&aacute;s d&ocirc;ležit&aacute;. Na tejto strane
              uv&aacute;dzame, čo sa deje s inform&aacute;ciami, ktor&eacute;
              zbierame a ulož&iacute;me, ako sa s nimi bude zaobch&aacute;dzať a
              ako bud&uacute; chr&aacute;nen&eacute; Va&scaron;e pr&aacute;va
              ako respondenta/respondentky.<br />Inform&aacute;cie bud&uacute;
              zhromažďovan&eacute; online prieskumom alebo
              prostredn&iacute;ctvom papierov&eacute;ho dotazn&iacute;ka. Ak ste
              (osoba, ktor&aacute; m&aacute; najbliž&scaron;ie narodeniny) v
              čase rozhovoru star&scaron;&iacute; ako 15 rokov a z&aacute;roveň
              ste nedovŕ&scaron;ili 18 rokov, mali by ste pred začat&iacute;m
              prieskumu požiadať o povolenie rodiča alebo opatrovn&iacute;ka.
              Ako rodič/opatrovn&iacute;k m&ocirc;žete požiadať o nahliadnutie
              do dotazn&iacute;ka vopred.
            </p>
            <p>
              <strong
                >Va&scaron;a &uacute;časť v prieskume Život na Slovensku</strong
              ><br />Ak s&uacute;hlas&iacute;te s &uacute;časťou v prieskume
              Život na Slovensku, budete požiadan&iacute;, aby ste odpovedali na
              s&eacute;riu ot&aacute;zok online, alebo m&ocirc;žete použiť
              papierov&uacute; verziu dotazn&iacute;ka.<br />Počas rozhovoru sa
              V&aacute;s budeme p&yacute;tať ot&aacute;zky o Va&scaron;ich
              postojoch, živote ale aj bežn&eacute; &uacute;daje. Budeme sa
              p&yacute;tať na politiku, spokojnosť a postoje k
              spoločensk&yacute;m ot&aacute;zkam. Budeme zbierať aj
              inform&aacute;cie o prostred&iacute;, v ktorom žijete.
            </p>
            <p>
              Viac inform&aacute;ci&iacute; o prieskume a v&yacute;sledky
              n&aacute;jdete tu:
              <a
                href="https://www.europeansocialsurvey.org/about/country-information/slovakia/slovak"
                target="_blank"
                >https://www.europeansocialsurvey.org/about/country-information/slovakia/slovak</a
              ><br />Vynalož&iacute;me maxim&aacute;lne &uacute;silie, aby sme
              zabezpečili, že vo v&yacute;sledkoch prieskumu alebo v
              ak&yacute;chkoľvek publik&aacute;ci&aacute;ch nebude možn&eacute;
              identifikovať žiadneho &uacute;častn&iacute;ka.
            </p>
            <p>
              <strong
                >Ak sa rozhodnete, že sa prieskumu
                nez&uacute;častn&iacute;te</strong
              ><br />M&ocirc;žete n&aacute;s kedykoľvek kontaktovať a povedať,
              že sa nechcete z&uacute;častniť prieskumu. Ak tak urob&iacute;te,
              v s&uacute;vislosti s t&yacute;mto prieskumom V&aacute;s už
              nebudeme kontaktovať. Mus&iacute;me overiť, či ľudia, ktor&iacute;
              sa z&uacute;častňuj&uacute; prieskumu, reprezentuj&uacute;
              celkov&uacute; popul&aacute;ciu. Preto budeme uchov&aacute;vať
              Va&scaron;e osobn&eacute; &uacute;daje až do ukončenia prieskumu.
              Inform&aacute;cie o Va&scaron;om s&uacute;krom&iacute; a
              pr&aacute;vach n&aacute;jdete niž&scaron;ie.
            </p>
            <p>
              <strong
                >Čo sa stane so z&iacute;skan&yacute;mi inform&aacute;ciami a s
                k&yacute;m bud&uacute; zdieľan&eacute;?</strong
              >
            </p>
            <ul>
              <li>
                Prieskum je vykon&aacute;van&yacute; ako &uacute;loha vo
                verejnom z&aacute;ujme a s ňou s&uacute;vis&iacute; aj
                v&yacute;skum a archiv&aacute;cia d&aacute;t. V&scaron;etko je v
                s&uacute;lade s nariaden&iacute;m GDPR<br />
                a slovenskou legislat&iacute;vou.
              </li>
              <li>
                K Va&scaron;ej adrese bude mať pr&iacute;stup iba Centrum
                spoločensk&yacute;ch a psychologick&yacute;ch vied SAV,
                ktor&eacute; V&aacute;m poslalo t&uacute;to pozv&aacute;nku.
              </li>
              <li>
                ESS ERIC pon&uacute;ka vyplnenie dotazn&iacute;ka online pomocou
                &scaron;pecializovanej prieskumnej platformy, ktor&uacute;
                prev&aacute;dzkuje Centerdata, nez&aacute;visl&yacute;<br />
                v&yacute;skumn&yacute; &uacute;stav so s&iacute;dlom v
                Holandsku. Pri použ&iacute;van&iacute; tejto platformy prieskumu
                sa Va&scaron;e odpovede ukladaj&uacute; v r&aacute;mci
                E&Uacute;. Počas prieskumu<br />
                a po jeho skončen&iacute; bud&uacute; mať k Va&scaron;im
                odpovediam pr&iacute;stup pracovn&iacute;ci Centra
                spoločensk&yacute;ch a psychologick&yacute;ch vied SAV,
                zamestnanci &uacute;stredia<br />
                ESS ERIC a Centerdata.
              </li>
              <li>
                Do ukončenia v&scaron;etk&yacute;ch prieskumov (december 2024)
                bud&uacute; mať zamestnanci Centra spoločensk&yacute;ch a
                psychologick&yacute;ch vied SAV pr&iacute;stup k Va&scaron;im<br />
                osobn&yacute;m &uacute;dajom. Zamestnanci spoločnosti Centerdata
                bud&uacute; mať pr&iacute;stup k Va&scaron;im odpovediam
                (vr&aacute;tane V&aacute;&scaron;ho mena a d&aacute;tumu
                narodenia,<br />
                ak ich poskytnete), ale nie k Va&scaron;ej adrese. Zamestnanci
                &uacute;stredia ESS ERIC (ESS ERIC HQ) m&ocirc;žu tiež
                z&iacute;skať pr&iacute;stup k Va&scaron;im odpovediam<br />
                z prieskumu, ak s&uacute; potrebn&eacute; na &uacute;čely
                kontroly kvality.
              </li>
              <li>
                Centr&aacute;la ESS ERIC sa nach&aacute;dza v Spojenom
                kr&aacute;ľovstve. V pr&iacute;padoch, keď je Centr&aacute;le
                ESS ERIC poskytnut&yacute; pr&iacute;stup k d&aacute;tam, bude
                sa tento pr&iacute;stup<br />
                považovať za prenos osobn&yacute;ch &uacute;dajov. Tento prevod
                bude založen&yacute; na rozhodnut&iacute; o primeranosti
                poskytnutom Komisiou E&Uacute;.
              </li>
              <li>
                Po dokončen&iacute; prieskumu Centrum spoločensk&yacute;ch a
                psychologick&yacute;ch vied SAV odo&scaron;le odpovede bez
                V&aacute;&scaron;ho mena alebo kontaktn&yacute;ch
                &uacute;dajov<br />
                do arch&iacute;vu &uacute;dajov ESS ERIC v Sikt (Agent&uacute;ra
                pre zdieľan&eacute; služby vo vzdel&aacute;van&iacute; a
                v&yacute;skume v N&oacute;rsku). Va&scaron;e odpovede sa spoja s
                ostatn&yacute;mi<br />
                odpoveďami a tie sa potom uložia v arch&iacute;ve ESS ERIC v
                Sikt. Rovnak&eacute; opatrenia na ochranu &uacute;dajov sa
                bud&uacute; uplatňovať v N&oacute;rsku.
              </li>
              <li>
                Va&scaron;e meno a kontaktn&eacute; inform&aacute;cie vymaže
                Centrum spoločensk&yacute;ch a psychologick&yacute;ch vied SAV
                po spracovan&iacute; &uacute;dajov Sikt najnesk&ocirc;r v
                decembri<br />
                2024. V s&uacute;časnosti uchov&aacute;van&eacute; k&oacute;pie
                &uacute;dajov z prieskumu v Centerdata bud&uacute; tiež
                vymazan&eacute;. Papierov&eacute; dotazn&iacute;ky, ak sa
                použij&uacute;, bud&uacute; tiež<br />
                zničen&eacute;. Zhromažden&eacute; &uacute;daje z prieskumov
                bud&uacute; bezpečne uložen&eacute; na dobu neurčit&uacute; v
                ESS ERIC Data Archive v N&oacute;rsku a nebud&uacute;
                zverejnen&eacute;.<br />
                Pr&iacute;stup k &uacute;dajom bud&uacute; mať
                v&yacute;skumn&iacute;ci až po schv&aacute;len&iacute;
                žiadost&iacute; a uzavret&iacute; doh&ocirc;d o
                d&ocirc;vernosti. Existuje možnosť, ale je to
                nepravdepodobn&eacute;,<br />
                že by sa niektor&eacute; z t&yacute;chto
                inform&aacute;ci&iacute; (napr&iacute;klad občianstvo, vek,
                krajina narodenia, povolanie, p&ocirc;vod a regi&oacute;n) mohli
                spojiť sp&ocirc;sobom, ktor&yacute; by<br />
                V&aacute;s identifikoval. Budeme sa v&scaron;ak snažiť riziko
                identifik&aacute;cie minimalizovať. Ak vypln&iacute;te prieskum
                online, zaznamen&aacute;me ďal&scaron;ie &uacute;daje,
                napr&iacute;klad<br />
                ako dlho V&aacute;m trvalo zodpovedanie každej ot&aacute;zky,
                ako aj ďal&scaron;ie inform&aacute;cie, napr&iacute;klad typ
                zariadenia, ktor&eacute; ste použili na vyplnenie prieskumu.
              </li>
            </ul>
            <p>
              <strong>Va&scaron;e pr&aacute;va</strong><br />Pokiaľ V&aacute;s
              budeme vedieť identifikovať, m&aacute;te pr&aacute;vo namietať
              proti spracovaniu Va&scaron;ich osobn&yacute;ch &uacute;dajov
              (napr. meno a adresa; &bdquo;Va&scaron;e osobn&eacute;
              &uacute;daje&ldquo;), požadovať opravu a vymazanie
              ak&yacute;chkoľvek inform&aacute;ci&iacute; o V&aacute;s.
              M&aacute;te pr&aacute;vo op&yacute;tať sa n&aacute;s, ak&eacute;
              inform&aacute;cie o V&aacute;s uchov&aacute;vame. M&aacute;te tiež
              pr&aacute;vo požiadať n&aacute;s, aby sme prestali
              spracov&aacute;vať Va&scaron;e osobn&eacute; &uacute;daje počas
              doby, ktor&aacute; je potrebn&aacute; na overenie ich
              spr&aacute;vnosti (napr. počkať, k&yacute;m bud&uacute;
              overen&eacute;). Akon&aacute;hle v&scaron;ak bud&uacute;
              odstr&aacute;nen&eacute; podrobnosti, ako je Va&scaron;e meno a
              adresa, je nepravdepodobn&eacute;, že V&aacute;s bude možn&eacute;
              s istotou identifikovať, a preto už nebude možn&eacute; vymazať
              zvy&scaron;n&eacute; inform&aacute;cie, ktor&eacute; ste poskytli.
              Ak požiadate o &bdquo;žiaden ďal&scaron;&iacute; kontakt&ldquo;,
              nebudeme V&aacute;s kontaktovať a osobn&eacute; &uacute;daje
              bud&uacute; na konci projektu (po finaliz&aacute;cii
              &uacute;dajov) anonymizovan&eacute;. M&aacute;te pr&aacute;vo
              podať sťažnosť &Uacute;radu na ochranu osobn&yacute;ch
              &uacute;dajov Slovenskej republiky alebo &uacute;radu Informačnej
              komisie Spojen&eacute;ho kr&aacute;ľovstva. M&ocirc;žete sa tiež
              obr&aacute;tiť na &uacute;radn&iacute;ka pre ochranu &uacute;dajov
              ESS ERIC alebo jeho z&aacute;stupcu pre E&Uacute;-GDPR.
            </p>
            <p>
              <strong>Kto nesie zodpovednosť?</strong> <br />Eur&oacute;pska
              soci&aacute;lna sonda je Eur&oacute;pske konzorcium pre
              v&yacute;skumn&uacute; infra&scaron;trukt&uacute;ru (ESS ERIC),
              vykon&aacute;va prieskum a je kontrol&oacute;rom. Jej
              &uacute;stredie (ESS ERIC HQ) je v City University of London,
              Spojen&eacute; kr&aacute;ľovstvo.
            </p>
            <p>
              <strong
                >Ktor&eacute; organiz&aacute;cie s&uacute;
                z&uacute;častnen&eacute;?</strong
              >
              <br />Na Slovensku je zodpovednou organi&aacute;ciou za
              n&aacute;bor a uskutočnenie prieskumu Centrum spoločensk&yacute;ch
              a psychologick&yacute;ch vied SAV. Prehľad kľ&uacute;čov&yacute;ch
              členov tohto t&iacute;mu n&aacute;jdete tu:
              <a
                href="https://www.europeansocialsurvey.org/about/country-information/slovakia/slovak"
                target="_blank"
                >https://www.europeansocialsurvey.org/about/country-information/slovakia/slovak</a
              ><br />Centerdata, nez&aacute;visl&yacute; neziskov&yacute;
              v&yacute;skumn&yacute; &uacute;stav so s&iacute;dlom v
              are&aacute;li univerzity v Tilburgu v Holandsku, je
              zodpovedn&yacute; za organiz&aacute;ciu online prieskumu.
              Ďal&scaron;ie podrobnosti o Centerdata n&aacute;jdete na
              str&aacute;nke
              <a href="https://www.centerdata.nl/en/" target="_blank"
                >https://www.centerdata.nl/en/</a
              >.
            </p>
            <p>
              <strong
                >Čo n&aacute;m d&aacute;va pr&aacute;vo zhromažďovať va&scaron;e
                osobn&eacute; &uacute;daje?</strong
              ><br />Va&scaron;e osobn&eacute; &uacute;daje &ndash;
              napr&iacute;klad Va&scaron;e meno &ndash; bud&uacute;
              zhromažďovan&eacute; za &uacute;čelom realiz&aacute;cie
              v&yacute;skumn&eacute;ho projektu o soci&aacute;lnych postojoch na
              Slovensku. Pr&aacute;vny r&aacute;mec pre realiz&aacute;ciu
              prieskumu Život na Slovensku je založen&yacute; na tom, že sa
              vykon&aacute;va ako &uacute;loha vo verejnom z&aacute;ujme,
              potrebn&yacute; na &uacute;čely v&yacute;skumu a
              archiv&aacute;cie, v s&uacute;lade so v&scaron;eobecn&yacute;m
              nariaden&iacute;m o ochrane osobn&yacute;ch &uacute;dajov a
              n&aacute;rodn&yacute;mi pr&aacute;vnymi predpismi.
            </p>
            <p>
              <strong>Kde sa dozviem viac?</strong><br />Ak sa chcete dozvedieť
              viac o v&yacute;skume, kontaktujte Michal Kento&scaron;, 0908 642
              362 alebo zns@saske.sk
            </p>
            <p>
              <strong>Kontakty</strong><br />N&aacute;rodn&yacute; t&iacute;m:
              Michal Kento&scaron;, 0944 631 186,
              <a href="mailto:zns@saske.sk">zns@saske.sk</a>,<br />Centrum
              spoločensk&yacute;ch a psychologick&yacute;ch vied SAV,
              Karpatsk&aacute; 5, 040 01 Ko&scaron;ice<br />Kontrol&oacute;r:
              ESS ERIC, ESS ERIC HQ, c/o City, University of London,
              <a href="mailto:ess@city.ac.uk">ess@city.ac.uk</a><br />Osoby
              dohliadaj&uacute;ce na ochranu &uacute;dajov: Lasse Raa and Callan
              Ramewal,
              <a href="mailto:dpo.esseric@europeansocialsurvey.org"
                >dpo.esseric@europeansocialsurvey.org</a
              ><br />N&aacute;rodn&aacute; autorita: &Uacute;rad na ochranu
              osobn&yacute;ch &uacute;dajov Slovenskej republiky,
              Hraničn&aacute; 12, 820 07 Bratislava 27, Slovensk&aacute;
              republika, statny.dozor@pdp.gov.sk
            </p>
            <p>
              <strong
                >Ak si žel&aacute;te podať sťažnosť, pros&iacute;m
                kontaktujte:</strong
              >
              <br /><strong>UK Information Commission&rsquo;s Office:</strong>
              <br />Po&scaron;tou: Wycliffe House, Water Lane, Wilmslow,
              Cheshire SK9 5AF<br /><strong>ESS ERIC GDPR kontakt</strong>:
              <a href="mailto:ess-gdpr@cessda.eu">ess-gdpr@cessda.eu</a
              ><br />Pozrite si vyhl&aacute;senie -
              <a
                href="http://www.europeansocialsurvey.org/about/privacy.html"
                target="_blank"
                >www.europeansocialsurvey.org/about/privacy.html</a
              >
            </p>
          </div>
        </div>
        <div class="close-container" @click="onClose()">
          <div class="leftright" :class="{ ready: !isLoading }"></div>
          <div class="rightleft" :class="{ ready: !isLoading }"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.red,
a {
  color: #ad1400 !important;
}
p {
  width: 80%;
  line-height: 130%;
  font-size: 18px;
  margin: 20px !important;
}
li {
  font-family: "Samo Sans Pro";
}
</style>
<script>
import Loader from "./Loader-comp.vue";

export default {
  components: { Loader },
  data() {
    return {
      isLoading: false,
      data: null,
      ready: true,
    };
  },

  methods: {
    onClose() {
      this.$router.push("/");
    },
  },
};
</script>

<style></style>

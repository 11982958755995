import Vue from "vue";
import VueRouter from "vue-router";
import Us from "../vue/Us-comp.vue";
import Shop from "../vue/Shop-comp.vue";
import Contact from "../vue/Contact-comp.vue";
// import Journal from "../vue/Journal";
// import Article from "../vue/Article";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Root",
  },
  {
    path: "/gdpr",
    name: "Us",
    component: Us,
  },
  {
    path: "/prieskum",
    name: "Shop",
    component: Shop,
  },
  {
    path: "/kontakt",
    name: "Social",
    component: Contact,
  },
  // {
  //   path: "/journal",
  //   name: "Journal",
  //   component: Journal,
  // },
  // {
  //   path: "/journal/:slug",
  //   name: "Article",
  //   component: Article,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
});

export default router;

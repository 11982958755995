<template>
  <div id="container">
    <div id="loader">
      <Logo></Logo>
    </div>
    <!-- <scroll-view class="bckg-slide" :plugins="scrollPlugin">
      <div>
      </div>
    </scroll-view> -->
    <div
      class="bckg-slide background"
      style="background-image: url(img/zivotnaslovensku.jpg)"
    ></div>

    <div id="overlay">
      <Logo class="logo-home"></Logo>
      <p class="text-home">
        <span style="text-decoration: underline">Život na Slovensku</span> je
        prieskum sociálnych postojov, názorov a správania obyvateľov európskych
        krajín. Tento rok sa pýtame na názory na témy, ako je oblasť, v ktorej
        žijete, ako dobre si vláda plní svoje úlohy a na životné prostredie.<br />
      </p>
      <a
        href="https://ess.centerdata.nl/questionnaires/ess11_slovakia"
        target="_blank"
        class="button"
        ><Sipka></Sipka> ZAČNI PRIESKUM</a
      >
      <p class="text-home">
        Ako prejav vďaky pošleme odmenu (5€) všetkým respondentom (osoba staršia
        ako 15 rokov s najbližšími narodeninami), ktorí vyplnia dotazník.
        <br /><br />
        Ak máte akékoľvek otázky, kontaktujte nás na čísle
        <span class="red">0944 631 186</span> alebo e-mailom na adrese
        <a href="mailto:zns@saske.sk">zns@saske.sk</a> <br /><br />Ako je
        uvedené v informačnom liste o ochrane osobných údajov, máte právo
        namietať proti spracovaniu vašich osobných údajov, opraviť a vymazať
        všetky informácie o vás a požiadať nás o informácie, ktoré o vás máme,
        pokiaľ vás dokážeme identifikovať. Máte tiež právo požiadať o
        pozastavenie spracovávania vašich údajov počas doby potrebnej na
        overenie ich správnosti a podať sťažnosť vnútroštátnemu dozornému
        orgánu. Ak sa chcete dozvedieť viac o prieskume, kliknite
        <a
          href="https://www.europeansocialsurvey.org/about/country-information/slovakia/slovak"
          target="_blank"
        >
          TU</a
        >
        <br /><br />
        Informácie o ochrane osobných údajov pre účastníkov prieskumu si môžete
        pozrieť <router-link to="/gdpr">TU</router-link>
      </p>
      <div
        id="menu-icon"
        @click="mobileMenu = !mobileMenu"
        :class="{ close: mobileMenu == true }"
      >
        <div></div>
        <div></div>
        <div></div>
      </div>

      <nav id="menu" :class="{ 'menu-open': mobileMenu == true }">
        <Logo v-if="mobileMenu" class="logo-home-menu"></Logo><br />
        <!-- <router-link to="/prieskum">PRIESKUM 01</router-link><br /> -->
        <router-link to="/gdpr">GDPR</router-link><br />
        <router-link to="/kontakt">KONTAKT</router-link><br />
        <img src="img/svusav.svg" alt="svusav" />
      </nav>
    </div>
    <transition name="modal">
      <router-view :key="$route.path"></router-view>
    </transition>
  </div>
</template>

<style lang="scss">
.logo-home {
  .fil0 {
    fill: #fff;
  }
}
.logo-home-menu {
  .fil0 {
    fill: #ad1400;
  }
}
.text-home {
  color: white;
  font-family: "Samo Sans Pro";
  width: 70%;
  font-size: 2vh;
  pointer-events: all;
}
p {
  a {
    color: #fff !important;
    font-weight: bold;
  }
}
.button {
  font-family: "Samo Sans Pro";
  display: flex;
  gap: 10px;
  width: fit-content;
  text-decoration: none;
  border: solid 1px white;
  padding: 10px;
  color: #fff !important;
  font-weight: bold;
  font-size: 2vh;
  pointer-events: all;
  align-items: center;
  height: 2vh;
  svg {
    height: 100%;
    .str0 {
      transition: all 0.3s ease-in-out;
    }
  }
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: white;
    color: black !important;
    svg .str0 {
      stroke: black !important;
    }
  }
}
</style>

<script>
import OverScrollPlugin from "./overscroll";
// import ScrollView from "@blackbp/vue-smooth-scrollbar";
import Logo from "./vue/Logo-comp.vue";
import Sipka from "./vue/Sipka-comp.vue";

export default {
  data() {
    return {
      lang: null,
      mobileMenu: false,
      scrollPlugin: [OverScrollPlugin],
      agileOptions: {
        autoplay: true,
        autoplaySpeed: 8000,
        fade: true,
        timing: "ease-in-out",
        speed: 2000,
        dots: false,
        navButtons: false,
        pauseOnHover: false,
      },
    };
  },
  components: {
    Logo,
    Sipka,
  },
};
</script>
